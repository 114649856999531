import { EnvConst } from 'consts/commonConst'

// REM日常点検環境
export const envConst: EnvConst = {
  ENVIRONMENT: 'rem-daily',
  DATASET_ID: 6261997348372506,
  FACILITY_NAME: 'REM実証棟',
  BACKEND_API_ROOT_URL:
    'https://jma3xru0ic.execute-api.ap-northeast-1.amazonaws.com',
  CDF_PROJECT: 'ak-sfx'
}
